import { Tag } from "@ui/components/data-display/tag/Tag";
import { badgeStyles } from "@components/ui/product-badges/styles";
import type { Badge } from "@components/ui/product-badges/types";
import { badgesStyles, ProductBadgeVariant } from "@components/ui/product-badges/types";
import { getBadge } from "@components/ui/product-badges/utils";
import type { IMicrocopy } from "@contentful-api/types/contentful";
import { Flex } from "@ui/components/layout/flex/Flex";
import { isEmpty } from "lodash-es";
import type { FC } from "react";
import { useMemo } from "react";
import { space } from "ui/src/design-tokens";

export interface ProductBadgesProps {
    badgesMicrocopy: { [key: string]: string };
    discount?: string;
    productBadge?: IMicrocopy;
    productBadgeColor?: string;
    badges?: string[];
    isOnProductTile?: boolean;
}

export const badgesContainer = "badges-container";
export const discountBadge = "discount";

// TODO Add to market configuration
export const ON_SHELF_DATE_LIMIT = 30;
export const ProductBadges: FC<ProductBadgesProps> = ({
    badgesMicrocopy,
    discount,
    productBadge,
    productBadgeColor,
    badges,
    isOnProductTile = true,
}) => {
    const getMicrocopyForBadge = (badge: string) => {
        return Object.keys(badgesMicrocopy).find((key) =>
            key.toLowerCase().includes(badge.toLowerCase())
        );
    };

    const productBadges: Badge[] = useMemo(() => {
        const availableBadges: Badge[] = [];
        const badgesWithMicrocopies: { badge: string; microcopy: string }[] = [];
        badges?.forEach((b) => {
            const microcopy = getMicrocopyForBadge(b);

            if (microcopy) {
                badgesWithMicrocopies.push({
                    badge: b,
                    microcopy,
                });
            }
        });

        const saleBadgeIndex = badgesWithMicrocopies?.findIndex((b) => b.badge === "Sale");

        const newArrivalBadgeIndex = badgesWithMicrocopies?.findIndex(
            (b) => b.badge === "NewArrival"
        );

        if (discount) {
            availableBadges.push({
                name: discount,
                type: ProductBadgeVariant.DEFAULT,
                testId: discountBadge,
                order: 1,
            });
        } else if (saleBadgeIndex !== -1) {
            availableBadges.push(getBadge("product.badge.sale"));
        } else if (newArrivalBadgeIndex !== -1) {
            availableBadges.push(getBadge("product.badge.newArrivals"));
        }

        if (saleBadgeIndex !== -1) {
            badgesWithMicrocopies?.splice(saleBadgeIndex, 1);
        }

        if (newArrivalBadgeIndex !== -1) {
            badgesWithMicrocopies?.splice(newArrivalBadgeIndex, 1);
        }

        if (productBadge) {
            let badgeType = ProductBadgeVariant.DEFAULT;
            let order = 2;

            // black_friday and cyber_monday come from contentful, and they can't be edited by content editors
            if (productBadgeColor === "black_friday") {
                badgeType = ProductBadgeVariant.BLACK_FRIDAY;
                order = 0;
            }
            if (productBadgeColor === "cyber_monday") {
                badgeType = ProductBadgeVariant.CYBER_MONDAY;
                order = 0;
            }

            availableBadges.push({
                name: productBadge?.fields?.key,
                type: badgeType,
                testId: productBadge?.fields?.value,
                order: order,
            });
        }

        badgesWithMicrocopies?.forEach((badge) => {
            availableBadges.push(getBadge(badge.microcopy));
        });

        return availableBadges.sort(({ order: a }, { order: b }) => a - b).slice(0, 3);
    }, [discount, productBadge, badges]);

    if (isEmpty(productBadges)) {
        return null;
    }

    return (
        <Flex
            alignItems={"center"}
            gap={space["0.2"]}
            //mt={4}
            flexWrap={"wrap"}
            data-testid={badgesContainer}
            sx={{
                ...(isOnProductTile
                    ? { position: "absolute", top: [3, 4], left: [3, 4, 4, 6], right: 10 }
                    : {}),
            }}
        >
            {productBadges
                ?.filter((badge) => !!badge?.name)
                ?.map?.((badge, index) => {
                    return (
                        <Tag
                            data-testid={`badge-${badge?.testId}`}
                            key={`badge-${badge.name}`}
                            sx={{
                                ...badgeStyles,
                                ...badgesStyles[badge.type],
                                paddingX: [1.2, 2],
                                paddingY: [0.6, 1],
                            }}
                            display={index > 2 ? "none" : "block"}
                        >
                            {badge?.testId === discountBadge
                                ? badge.name
                                : badgesMicrocopy[badge.name] || badge.name}
                        </Tag>
                    );
                })}
        </Flex>
    );
};
