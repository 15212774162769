export const badgeStyles = {
    fontSize: ["mobileBodyTextTiny", "mobileBodyTextSmall", "desktopBodyTextSmall"],
    height: "auto",
    textAlign: "start",
    textTransform: "capitalize",
    fontWeight: "semibold",
    lineHeight: ["4", "5"],
    borderWidth: ["0.2"],
    borderStyle: "solid",
};

export const defaultBadgeStyles = {
    bg: "gray.150",
    color: "black",
    borderColor: "gray.150",
};

export const blackFridayStyles = {
    bg: "#000000",
    color: "white",
    borderColor: "#000000",
};

export const cyberMondayStyles = {
    bg: "#3B61A1",
    color: "white",
    borderColor: "#3B61A1",
};
