import type { IPdpConfigurationFields } from "@contentful-api/types/contentful";
import type { Badge, CustomBadgeWithProducts } from "./types";
import { badgesMap } from "./types";
import fetchService from "@lib/helpers/fetchService";

export const getBadge = (name: string): Badge | undefined =>
    badgesMap.find((badge) => badge.name === name);

export const getCustomBadgeBySkus = async ({
    skus,
    locale,
    localeTagId,
}: {
    skus: IPdpConfigurationFields["product"];
    locale: string;
    localeTagId: string;
}): Promise<CustomBadgeWithProducts> => {
    return fetchService
        .post<any, CustomBadgeWithProducts>("/api/product/skus", {
            skus,
            locale,
            localeTagId,
            selectFields: "fields.product, fields.productBadge, fields.productBadgeColor",
        })
        .then((response) => response)
        .catch(() => {
            return {
                badge: undefined,
                color: undefined,
                products: [],
            };
        });
};
