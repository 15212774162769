import type { FC, MouseEventHandler } from "react";
import React, { useCallback, useMemo, useState } from "react";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { Text } from "@ui/components/content/text/Text";
import { useDisclosure } from "@ui/hooks/useDisclosure";
import { useThemeMediaQuery } from "@ui/hooks/useThemeMediaQuery";
import type { FlexProps } from "@ui/components/layout/flex/Flex";
import { Money } from "@ui/components/data-display/money/Money";
import { ProductImages } from "./product-images/ProductImages";
import { LinkStyles } from "./ProductTile.styles";
import { getPDPSlug } from "@ui/utils/getPDPSlug";
import { productNameTransform } from "@ui/utils/productNameTransform";
import { IconButton } from "@ui/components/forms/icon-button/IconButton";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { useInView } from "react-intersection-observer";
import { NextLink } from "@ui/components/navigation/link/NextLink";

export interface ProductTileProps extends FlexProps {
    productName: string;
    articleNumber: string;
    colorCode: string;
    image: string;
    imageLabel?: string;
    variants?: Variant[];
    productColors: {
        colorCode: string;
        image: string;
        imageLabel?: string;
        priceWithoutDiscount: Price;
        objectID: string;
        altTagPattern?: string;
        priceWithDiscount: Price;
        imageBackgroundColor: string;
        hasDiscount: boolean;
        hasStock?: boolean;
        discountPercentage: number;
        badges?: string[];
    }[];
    objectID: string;
    categories?: string[];
    turnOffHover?: boolean;
    aspectRatioImage?: string | string[];
    altTagPattern?: string;
    handleTileClick?: MouseEventHandler<HTMLAnchorElement>;
    hasDiscount?: boolean;
    hasStock?: boolean;
    locale: string;
    priceWithoutDiscount: Price;
    priceWithDiscount: Price;
    discountPercentage: number;
    colorLabel: string;
    imageBackgroundColor: string;
    tagComponent?: React.ReactElement;
    isAddingToWishlist?: boolean;
    onAddToWishlist?: (product: Product) => Promise<void>;
    descriptiveHeading?: string;
    onInViewPort?: (inView: boolean, item: any) => any;
    badges?: string[];
}

type Price = { centAmount: number; currencyCode: string; fractionDigits: number };
export type Variant = { size: string; availableQuantity: number; sku: string };

export type Product = Pick<
    ProductTileProps,
    | "priceWithDiscount"
    | "priceWithoutDiscount"
    | "image"
    | "imageLabel"
    | "variants"
    | "objectID"
    | "altTagPattern"
    | "colorCode"
    | "imageBackgroundColor"
    | "discountPercentage"
    | "hasDiscount"
    | "hasStock"
    | "badges"
>;

export const ProductTile: FC<ProductTileProps> = ({
    productName,
    articleNumber,
    colorCode,
    categories,
    image,
    imageLabel,
    variants,
    productColors,
    objectID,
    turnOffHover = false,
    aspectRatioImage,
    altTagPattern,
    handleTileClick,
    hasDiscount = false,
    hasStock,
    locale,
    priceWithoutDiscount,
    priceWithDiscount,
    discountPercentage,
    colorLabel,
    imageBackgroundColor,
    tagComponent,
    isAddingToWishlist,
    onAddToWishlist,
    descriptiveHeading,
    onInViewPort,
    badges,
    ...rest
}) => {
    const { isOpen: isExpanded, onOpen, onClose } = useDisclosure();
    const { md } = useThemeMediaQuery();
    const isMobile = md === false;

    const initialProduct = useMemo<Product>(() => {
        return {
            priceWithDiscount,
            priceWithoutDiscount,
            image,
            imageLabel,
            variants,
            objectID,
            altTagPattern,
            colorCode,
            imageBackgroundColor,
            discountPercentage,
            hasDiscount,
            hasStock,
            badges,
        };
    }, [
        colorCode,
        priceWithoutDiscount,
        objectID,
        priceWithDiscount,
        image,
        imageLabel,
        altTagPattern,
        variants,
        imageBackgroundColor,
        hasDiscount,
        hasStock,
        discountPercentage,
        badges,
    ]);

    const [displayedProduct, setDisplayedProduct] = useState<Product>(initialProduct);

    const handleOnOpen = () => {
        !turnOffHover && onOpen();
    };

    const handleOnClose = () => {
        !turnOffHover && onClose();
    };

    const { slug: productSlug } = useMemo(
        () => getPDPSlug(productName, articleNumber, displayedProduct.colorCode, locale),
        [productName, articleNumber, displayedProduct.colorCode, locale]
    );

    const tags = useMemo(() => {
        if (!tagComponent) {
            return null;
        }

        return React.cloneElement(tagComponent, {
            badges: displayedProduct.badges,
            discount: displayedProduct.discountPercentage
                ? `-${displayedProduct.discountPercentage}%`
                : undefined,
        });
    }, [displayedProduct, tagComponent]);

    const addToWishlist = useCallback(async () => {
        if (onAddToWishlist) {
            await onAddToWishlist(displayedProduct);
        }
    }, [onAddToWishlist, displayedProduct]);

    const { ref } = useInView({
        threshold: 0.5,
        onChange: (inView) => {
            if (onInViewPort) {
                onInViewPort(inView, objectID);
            }
        },
    });

    // We need to check if the next item is a button, if not we close the modal (that means is a link thus is another product tile)
    const handleOnBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        if (e.relatedTarget?.classList.contains("chakra-link")) {
            onClose();
        }
    };

    return (
        <Box
            data-testid="ProductTile"
            h={["full"]}
            ref={ref}
            {...rest}
            onFocus={onOpen}
            position="relative"
            onBlur={handleOnBlur}
        >
            <NextLink
                href={productSlug}
                onClick={handleTileClick}
                sx={LinkStyles}
                prefetch={false}
                tabIndex={0}
            >
                <Box
                    display={["flex", "flex"]}
                    flexDirection={"column"}
                    gridTemplateRows={turnOffHover ? "70% 30%" : ["70% 0% 30%", "70% 15% 15%"]}
                    h={["auto", "auto"]}
                    backgroundColor={"white"}
                    aria-label={productName}
                >
                    <ProductImages
                        setDisplayedProduct={setDisplayedProduct}
                        displayedProduct={displayedProduct}
                        initialProduct={initialProduct}
                        altTagPattern={altTagPattern}
                        productColors={productColors}
                        productName={productName}
                        isExpanded={isExpanded}
                        onOpen={handleOnOpen}
                        onClose={handleOnClose}
                        aspectRatioImage={aspectRatioImage}
                        imageBackgroundColor={imageBackgroundColor}
                        tags={tags}
                        productSlug={productSlug}
                    />
                    <Box
                        pt={2}
                        pl={[4, 4, 4, 6]}
                        transition="margin 150ms ease-in-out"
                        sx={{ containerType: "inline-size" }}
                    >
                        <Box as={"h2"} display="flex" flexDirection="column">
                            <Text
                                as={"span"}
                                fontWeight="bold"
                                className="product-name"
                                fontSize={["desktopBodyTextSmall", "desktopBodyTextNormal"]}
                                textTransform="capitalize"
                            >
                                {productNameTransform(productName)}
                            </Text>
                            {!isExpanded && descriptiveHeading && (
                                <Text
                                    as={"span"}
                                    fontWeight="bold"
                                    color="gray.500"
                                    className="product-colors"
                                    fontSize={["desktopBodyTextSmall", "desktopBodyTextNormal"]}
                                >
                                    {descriptiveHeading}
                                </Text>
                            )}
                        </Box>
                        {/* Colors */}
                        <Text
                            fontWeight="bold"
                            color="gray.500"
                            className="product-colors"
                            fontSize={["desktopBodyTextSmall", "desktopBodyTextNormal"]}
                        >
                            {!isExpanded ? colorLabel : null}
                        </Text>
                        {/* Product price and discounted price */}
                        <Flex
                            flexDir="row"
                            gap="2"
                            mt={isExpanded ? 0 : 3}
                            fontSize={["desktopBodyTextSmall", "desktopBodyTextNormal"]}
                            flexWrap={["wrap", "nowrap"]}
                            sx={{
                                transition: "margin 150ms ease-in-out",
                            }}
                        >
                            <Money
                                data={displayedProduct.priceWithDiscount}
                                locale={locale}
                                fontWeight="bold"
                                color={"black"}
                                className="product-price"
                            />
                            {displayedProduct.discountPercentage ? (
                                <Money
                                    data={displayedProduct.priceWithoutDiscount}
                                    locale={locale}
                                    fontWeight="bold"
                                    color="gray.500"
                                    textDecoration="line-through"
                                    className="product-discounted-price"
                                />
                            ) : null}
                        </Flex>
                    </Box>
                </Box>
            </NextLink>
            {/* As soon the link wrapper get focus, we need this element to be here to make it focusable after jumping from link. */}
            {(isExpanded || isMobile) && (
                <Box onMouseEnter={onOpen} onMouseLeave={onClose}>
                    <Flex position="absolute" top={1} right={1} zIndex="1">
                        <Flex gap={[0, 4, 6]} justifyContent="flex-end">
                            <IconButton
                                tabIndex={0}
                                size={"sm"}
                                aria-label="Add to wishlist"
                                color={"black"}
                                variant="tertiary"
                                sx={{
                                    bgColor: "transparent",
                                    "&:hover": {
                                        bgColor: "transparent",
                                    },
                                }}
                                icon={
                                    <ECCOIcon
                                        name={isAddingToWishlist ? "wishlist-filled" : "wishlist"}
                                    />
                                }
                                onClick={addToWishlist}
                            />
                        </Flex>
                    </Flex>
                </Box>
            )}
        </Box>
    );
};
