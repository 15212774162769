export const productNameTransform = (name: string) => {
    let transformedName = name?.toLowerCase();
    if (transformedName?.includes("ecco")) {
        transformedName = transformedName?.replace("ecco", "ECCO");
    }
    return transformedName;
};

export const getImageAltTag = (altTagPattern?: string, imageLabel?: string) => {
    if (!imageLabel) return altTagPattern;
    const imageAlt = imageLabel.charAt(0).toUpperCase() + imageLabel.slice(1);
    return `${altTagPattern} - ${imageAlt}`;
};
