import type { NextLinkProps } from "@ui/components/navigation/link/NextLink";

export const LinkStyles: NextLinkProps["sx"] = {
    w: "full",
    h: "full",
    textDecoration: "none",
    _hover: { textDecoration: "none" },
    _focus: { textDecoration: "none" },
    _active: { textDecoration: "none" },
    _visited: { textDecoration: "none" },
};
