import {
    defaultBadgeStyles,
    blackFridayStyles,
    cyberMondayStyles,
} from "@components/ui/product-badges/styles";
import type { IMicrocopy } from "@contentful-api/types/contentful";

export enum ProductBadgeVariant {
    DEFAULT = "default",
    BLACK_FRIDAY = "black_friday",
    CYBER_MONDAY = "cyber_monday",
}

export const badgesStyles = {
    [ProductBadgeVariant.DEFAULT]: defaultBadgeStyles,
    [ProductBadgeVariant.BLACK_FRIDAY]: blackFridayStyles,
    [ProductBadgeVariant.CYBER_MONDAY]: cyberMondayStyles,
};

export interface Badge {
    name: string;
    type: ProductBadgeVariant;
    testId: string;
    order: number;
}

export const badgesMap: Badge[] = [
    {
        name: "product.badge.blackFriday",
        type: ProductBadgeVariant.BLACK_FRIDAY,
        testId: "blackFriday",
        order: 1,
    },
    {
        name: "product.badge.cyberMonday",
        type: ProductBadgeVariant.CYBER_MONDAY,
        testId: "cyberMonday",
        order: 1,
    },
    {
        name: "product.badge.sale",
        type: ProductBadgeVariant.DEFAULT,
        testId: "sale",
        order: 1,
    },
    {
        name: "product.badge.newArrivals",
        type: ProductBadgeVariant.DEFAULT,
        testId: "newArrivals",
        order: 1,
    },
    {
        name: "product.badge.other",
        type: ProductBadgeVariant.DEFAULT,
        testId: "other",
        order: 2,
    },
    {
        name: "product.badge.onlineExclusive",
        type: ProductBadgeVariant.DEFAULT,
        testId: "onlineExclusive",
        order: 6,
    },
    {
        name: "product.badge.bestseller",
        type: ProductBadgeVariant.DEFAULT,
        testId: "bestseller",
        order: 3,
    },
    {
        name: "product.badge.upcycled",
        type: ProductBadgeVariant.DEFAULT,
        testId: "upcycled",
        order: 5,
    },
    {
        name: "product.badge.topRated",
        type: ProductBadgeVariant.DEFAULT,
        testId: "topRated",
        order: 4,
    },
];

export interface CustomBadgeWithProducts {
    badge: IMicrocopy;
    color: string;
    products: string[];
}
